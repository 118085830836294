@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgba(#000, 0.7);
  display: grid;
  justify-content: center;
  align-content: space-around;
  z-index: 100;
  grid-template-columns: minmax(min-content, 400px);
  overflow-y: auto;
}
