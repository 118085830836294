$minw: 220px;

$input-l: 2.5em;

$track-w: 100%;
$track-h: 0.5em;
$track-r: 0.5 * $track-h;

$thumb-d: 1em;
$thumb-r: 0.5 * $thumb-d;

@mixin track($f: 0) {
  border: none;
  width: 100%;
  height: $track-h;
  border-radius: $track-r;
  background: #d8d8d8;

  @if $f > 0 {
    background-image: linear-gradient(
      90deg,
      currentcolor calc(var(--js, 0) * var(--prog, 0) * var(--pos)),
      transparent 0
    );
  }
}

@mixin progr() {
  height: $track-h;
  border-radius: $track-r 0 0 $track-r;
  opacity: var(--prog, 0);
  background: currentcolor;
}

@mixin thumb($f: 0) {
  @if $f > 0 {
    margin-top: $track-r - $thumb-r;
  }
  border: none;
  width: calc((2 - var(--narr, 0)) *#{$thumb-r});
  height: $thumb-d;
  border-radius: $thumb-r;
  box-shadow: inset 0 0 0 calc((2 - var(--fill, 0)) *#{$thumb-r}/ 2) $blue;
  background: currentcolor;
}

// * {
//   margin: 0;
//   padding: 0;
//   font: inherit
// }

.js {
  --js: 1;
}

// body, section, div, datalist { display: grid }

body {
  --track-w: Min(
    #{$track-w},
    calc(Max(#{$minw}, 100vw) - #{$thumb-d} - 2 *#{$input-l})
  );
  // grid-template-columns: repeat(
  //   auto-fit,
  //   minmax(
  //     Max(#{$minw}, calc(var(--track-w) + #{$thumb-d} + 2 *#{$input-l})),
  //     1fr
  //   )
  // );
  // place-content: center;
  // min-height: 100vh;
  // font: 1em/ 1.5 ubuntu mono, consolas, monaco, monospace;
}

.stylized-range {
  --hs: 210, 100%;
  --ll: 87%;
  --lb: 55%;
  place-self: center;
  width: 100%;
  padding: $thumb-r;
  // filter: grayScale(calc(0.75 * (1 - var(--hl, 0))));
  transition: filter 0.3s;

  &--multi {
    --track-w: #{$track-w};
    grid-auto-flow: column;
    grid-gap: 0 $thumb-d;
    grid-template:
      var(--track-w) max-content/
      repeat(var(--n), $thumb-d);

    input[type='range'] {
      transform: rotate(-90deg);
    }
  }

  &--progr {
    --prog: 1;
    --min: 0;
    --max: 100;
    --rng: calc(var(--max) - var(--min));
    --pos: calc(
      #{$thumb-r} + (var(--val) - var(--min)) / var(--rng) * (var(--track-w) - #{$thumb-d})
    );
    grid-template-columns: $input-l var(--track-w) $input-l;

    input[type='range'] {
      grid-area: 1/ 2 / span 1 / span 1;
    }
  }

  &#{&}--not-responded {
    --hl: 0;
    --prog: 0;

    input[type='range'] {
      &::-moz-range-thumb {
        opacity: 0;
      }
      &::-webkit-slider-thumb {
        opacity: 0;
      }
    }
  }

  * {
    place-self: center;
  }

  &:focus-within,
  &:hover {
    --hl: 1;
  }

  input[type='range'] {
    &,
    &::-webkit-slider-thumb,
    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
    }

    background-color: transparent;
    place-self: center;
    z-index: 2;
    width: var(--track-w);
    height: $thumb-d;
    color: $faded-blue;
    cursor: pointer;

    &::-webkit-slider-runnable-track {
      @include track(1);
    }

    &::-webkit-slider-thumb {
      @include thumb(1);
    }

    &::-moz-range-track {
      @include track();
    }

    &::-moz-range-progress {
      @include progr();
    }

    &::-moz-range-thumb {
      @include thumb();
    }

    &:focus {
      outline: none;
    }
  }
}

// datalist {
//   grid-area: 1/ 1/ span 1/ -1;
//   grid-template: inherit
// }

// option {
//   &:last-child { grid-column: -2/ -1 }
// }
