@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.editor-grid {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 1rem;
  height: 100%;
  position: relative;
}

.main-body {
}

.tools {
  mask-image: linear-gradient(
    to bottom,
    transparent,
    black 5px,
    black calc(100% - 5px),
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    black 5px,
    black calc(100% - 5px),
    transparent
  );
}

.tools {
  display: flex;
  flex-direction: column;
  position: sticky;
  height: 100vh;
  top: 0px;
  padding-top: 5px;
  margin-top: -5px;
  overflow-y: auto;
}

.tool {
}

.drop-indicator {
  position: absolute;
  height: 5px;
  background-color: bs.$blue;
  width: calc(100% + 0px);
  opacity: 0.5;
  border-radius: 5px;
  left: 0;
  // top: 50%;

  // &.top {
  //   top: -5px;
  // }

  // &.bottom {
  //   bottom: -5px;
  // }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}

.type {
  align-self: center;

  :global(.action-selector .dropdown-toggle) {
    width: 14em;
  }
}

.actions {
  align-self: center;
}

// .settings-wrapper {
//   .settings-drawer {
//     padding: 1rem;
//     background-color: #f8f9fa;
//     border-top: 1px solid #dee2e6;

//     .settings-content {
//       .settings-field {
//         margin-bottom: 1rem;

//         label {
//           display: block;
//           font-size: 0.875rem;
//           font-weight: 500;
//           margin-bottom: 0.5rem;
//           color: #495057;
//         }
//       }
//     }
//   }
// }

.question-body {
  padding: 0 15px;
}

.settings-wrapper {
  .settings-drawer {
    background-color: bs.$smoke-bg;
    padding: 0.5rem 15px;
    border: 1px solid bs.$dark-gray;
    border-left: none;
    border-right: none;
    margin-bottom: 1rem;

    label {
      margin-bottom: 0;
    }

    :global(.answer-set-selector) {
      :global(.dropdown-toggle) {
        width: unset;

        > div {
          width: 15em;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

:global(.hide-settings) {
  .settings-drawer {
    display: none;
    visibility: hidden;
  }
}
:global(.setting-only) {
  display: none;
  visibility: hidden;
}
:global(.setting-only) {
  display: none;
  visibility: hidden;
}
