@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.capabilities-set-question-set {
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
}

.capabilities-set-capability-description {
  font-size: 0.8rem;
  color: bs.$body-subdued-font-color;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.capabilities-set-question-set-text {
  // font-size: 0.8rem;
  margin-bottom: 1rem;
}

.capabilities-set-questions {
  display: grid;
  // grid-template-columns: 0.8fr 1fr;
  grid-template-columns: 1fr;
  max-width: 900px;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 0.8fr 1fr;

    .capabilities-set-question-range {
      padding: 0 70px;
    }
  }
}

.capabilities-set-header {
  display: contents;
  font-size: 0.8rem;

  > * {
    position: sticky;
    top: 0;
    padding: 0.75rem 10px;
    background-color: bs.$panel-blue;
    z-index: 10;
  }
}

:global(.survey-assessment-run:has(.progress-slider))
  .capabilities-set-header
  > * {
  top: 69.5859px;
}

.capabilities-set-header-minmax {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.minmax-label {
  width: 120px;
  text-align: center;
  font-weight: bold;
}

.capabilities-set-question {
  display: contents;

  > * {
    padding: 1rem 10px;
    align-content: center;
  }
}

.capabilities-set-question:nth-child(odd) > * {
  background-color: bs.$smoke-bg;
}

.capabilities-set-question-range {
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.capabilities-set-question-range-label {
  text-align: center;
  font-size: 0.8rem;
  min-height: 2.75rem;
  // max-height: 2.5rem;
  align-content: flex-end;
  padding: 0 15px 0.25rem 15px;
}

.clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

.capabilities-set-question-range-input {
  width: 100%;
}

.capabilities-set-question-range-na {
  width: 100%;
  min-height: 2.75rem;
  text-align: center;
  padding-top: 0.75rem;
  font-size: 0.8rem;
}
