@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.status-badge {
  font-size: 0.6rem;
  padding: 0.2rem 0.5rem;
  border-radius: bs.$border-radius-small;
  border: 1px solid transparent;
  text-transform: uppercase;
  margin-bottom: 18px;

  &.draft {
    border-color: bs.$orange;
    color: bs.$orange;
  }

  &.published {
    border-color: bs.$blue;
    color: bs.$blue;
  }

  &.archived {
    border-color: bs.$dark-gray;
    color: bs.$dark-gray;
  }

  &.live {
    border-color: bs.$green;
    color: bs.$green;
  }

  &.closed {
    border-color: bs.$purple;
    color: bs.$purple;
  }
}

.assessment-summary {
  display: grid;
  grid-template-columns: 10px 1fr;
  width: 100%;

  &.draft .assessment-summary-status-bar {
    background-color: bs.$orange;
  }

  &.published .assessment-summary-status-bar {
    background-color: bs.$blue;
  }

  &.archived .assessment-summary-status-bar {
    background-color: bs.$dark-gray;
  }

  &.live .assessment-summary-status-bar {
    background-color: bs.$green;
  }

  &.closed .assessment-summary-status-bar {
    background-color: bs.$purple;
  }
}

.assessment-summary-status-bar {
  border-radius: bs.$border-radius-small 0 0 bs.$border-radius-small;
  background-color: bs.$black-color;
}

.assessment-summary-content {
  border-radius: 0 bs.$border-radius-small bs.$border-radius-small 0;
  border: 1px solid bs.$dark-gray;
  border-left: none;
  padding: 10px;
}

.assessment-summary-date {
  font-size: 0.8rem;
  color: bs.$dark-gray;
}

.assessment-summary-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.assessment-summary-status {
  float: right;
}

.assessment-summary-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
