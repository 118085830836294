.survey-assessment-run {
  position: relative;
  top: -2.55rem;
  margin-bottom: -2.55rem;
}

.survey-assessment-review {
  .btn-hero {
    width: 100%;
  }
  .btn-container {
    width: 100%;
  }

  @media #{$medium-up} {
    .btn-container {
      width: auto;
    }
  }

  .complete-icon {
    color: $green;
  }
  .incomplete-icon {
    color: $blue;
  }
}

.survey-assessment-review-chart {
  margin: -20px 0 -6px;
  padding: 0 15px;
  position: relative;
  z-index: 3;
}

.survey-assessment-back-nav {
  font-size: 0.8rem;
  background: $black-color;
  color: $white-color;
  position: relative;
  top: -1rem;
  margin-bottom: -1rem;
  z-index: 2000;
  margin: 0 -15px -1rem;
  padding: 0 15px 1rem;
  background-color: $black-color;

  &:has(~ .progress-slider) {
    padding-bottom: 0;
  }

  a {
    color: $white-color;
  }
}
