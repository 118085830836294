@use '/src/styles/greenleaf/bootstrap_variables' as bs;

$card-width: 350px;
$card-height: 200px;

:global(.react-horizontal-scrolling-menu--wrapper) {
  margin: -8px;
}

.sources {
  display: grid;
  grid-template-columns: min-content 1fr;
  width: 100%;
  gap: 20px 1rem;

  .assessment-source {
    overflow-x: hidden;

    > :global(.card) {
      margin: 0;
      display: flex;
      flex-direction: column;

      :global(.card-header) {
        flex-grow: 0;
      }

      :global(.card-body) {
        flex-grow: 1;
        align-content: flex-end;
      }
    }

    :global(.card) {
      height: 100%;
    }

    :global(.card-body) {
      overflow-x: hidden;
    }

    :global(.card-header) p:last-child {
      margin-bottom: 0;
    }
  }

  :global(#use-template) {
    grid-column: 1 / -1;
  }

  :global(.react-horizontal-scrolling-menu--inner-wrapper) {
    position: relative;
  }

  :global(.react-horizontal-scrolling-menu--arrow-left .background-gradient) {
    mask: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  }

  :global(.react-horizontal-scrolling-menu--arrow-right .background-gradient) {
    mask: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  }

  :global(.react-horizontal-scrolling-menu--arrow-left),
  :global(.react-horizontal-scrolling-menu--arrow-right) {
    position: absolute;
    z-index: 2;
    height: 100%;

    :global(.background-gradient) {
      position: absolute;
      backdrop-filter: blur(64px);
      background-color: #fff;
      height: 100%;
      width: 100%;
    }

    button {
      height: 100%;
      position: relative;
      z-index: 3;
    }
  }
  :global(.react-horizontal-scrolling-menu--arrow-right) {
    right: 0;
  }

  :global(.react-horizontal-scrolling-menu--scroll-container) {
    // gap: 0.5rem;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  :global(.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar) {
    display: none;
  }
}

.arrow-button {
  opacity: 1;
  border: none;
  background: transparent;
  width: 40px;
  color: bs.$dark-gray;

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    color: bs.$primary-color;
  }
}

.arrow-button[disabled] {
  pointer-events: none;
  position: absolute;
  display: none;
}

.scratch-button {
  width: $card-width;
  height: $card-height;
  margin: 0;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.source-card {
  display: flex;
  padding: 0;
  margin: 8px;
  width: $card-width;
  height: $card-height;
  flex-direction: column;
  justify-items: left;
  text-align: left;

  &:hover .source-content {
    border-color: #af599f;
  }
  &:active .source-content {
    border-color: #1f8ea6;
  }
}

.source-content {
  transition: border-color 0.2s ease-in-out;
  border: 1px solid bs.$dark-gray;
  border-radius: bs.$border-radius-small;
  background-color: bs.$smoke-bg;
  padding: 10px 15px;
  width: 100%;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.source-title {
  font-size: 1.2rem;
  color: bs.$black-color;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 0 auto;
}

.a {
  font-size: 1.2rem;
  // margin-bottom: 0.5rem;
}

.source-body {
  font-size: 0.8rem;
  color: bs.$dark-gray;
  flex: 1 0 auto;
}

.source-button {
  width: 100%;
  height: 30px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  // text-align: center;
}

.template-content,
.recent-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.template-description,
.recent-capabilities {
  font-size: 0.8rem;
  color: bs.$dark-gray;
  flex: 1 0 auto;
  padding: 5px 8px;
}

.template-stats,
.recent-last-run {
  color: bs.$dark-gray;
  font-size: 1rem;
  flex: 0 0 auto;
  color: bs.$black-color;

  label {
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0;
  }
}

.template-description {
  padding: 0;
}

.template-description-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}

.template-stats {
  padding: 5px 8px;
}

.template-stats-item {
  display: block;
  margin-top: 0.25rem;
}
