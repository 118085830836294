.chart-neutral-line {
  stroke: $chart-stroke-color;
  stroke-width: 1px;
}
.chart-highlight-line {
  stroke: $blue;
  stroke-width: 1px;
}

.chart-axis {
  stroke: $chart-stroke-color;
  stroke-width: 1.5px;

  line {
    stroke: $chart-stroke-color;
  }
}

.chart-inline-tick {
  stroke: $chart-stroke-color;
  stroke-width: 1px;
  stroke-dasharray: 3 3;
}

.chart-grid {
  stroke-dasharray: 1 6;
}

.chart-grid-dense {
  stroke-dasharray: 1 3;
  pointer-events: none;
}

.chart-link {
  stroke: $chart-link-color;
  stroke-width: 1;
  fill: none;
}

.no-response-fill {
  fill: $no-response-marker-color;
}
.no-response-stroke {
  stroke: $no-response-marker-color;
}

.tick-label {
  fill: $chart-stroke-color;
  font-family: $sans-serif-family;
}
.tick-label-highlight {
  fill: $blue;
}

.no-response-text {
  font-size: 0.6rem;
  fill: $no-response-marker-color;
  color: $no-response-marker-color;
}

.na-response-text {
  font-size: 0.6rem;
  color: $blue;
  fill: $blue;
}

.chart-axis.chart-light {
  stroke: #c8c8c8;
  stroke-dasharray: 1 3;
}

.chart-median.chart-light {
  stroke: #c8c8c8;
  stroke-width: 1.5px;
  // stroke-dasharray: 2 2;
}

.chart-axis-label {
  font-size: 10px;
  fill: #c8c8c8;

  &.hanging {
    dominant-baseline: hanging;
  }
}
