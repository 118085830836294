@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.escape {
  z-index: 10000;
  transition: transform 0s;
}

.selector {
  // transform: none !important;

  :global(.modal-content) {
    height: calc(100vh - 2rem);
  }
  &:global(.modal-dialog) {
    margin: 1rem auto;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .capability-set-selector-wrapper {
    padding-top: 3px;
    position: sticky;
    // padding: 0 3px;
    // margin: 0 -3px;
    top: 0;
    z-index: 1000;
    background: linear-gradient(
      to bottom,
      rgba(bs.$white-color, 1) 75%,
      rgba(bs.$white-color, 0) 100%
    );

    &.active-item::before {
      left: -5px;
      top: 3px;
      height: calc(100% - 3px);
      width: calc(100% + 5px);
    }
  }

  .capability-set-selector {
    display: flex;
    position: relative;
    z-index: 1;

    // background: linear-gradient(
    //   to bottom,
    //   rgba(red, 1) 65%,
    //   rgba(red, 0) 100%
    // );
    // background-color: rgba(bs.$white-color, 0.5);
    // background-color: rgba(red, 0.5);

    > *:first-child {
      flex: 1 1 auto;
      padding-left: 0;
      padding-right: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      outline: none;

      &:hover {
        color: bs.$primary-color;
        text-decoration: underline;
      }
    }

    > *:last-child {
      flex: 0 0 auto;
    }

    button {
      padding: 0 0.5rem;
      height: 100%;
      background-color: transparent;
      border: none;
      font-size: 1rem;
      font-weight: 600;
      color: bs.$black-color;
      font-weight: normal;

      &:global(.dropdown-toggle):hover {
        color: bs.$white-color;
      }
    }

    :global(.dropdown-menu[aria-hidden='true']) {
      display: none;
    }
  }

  :global(.modal-content) {
    overflow: hidden;
  }

  :global(.modal-header) {
    padding: 1rem;
    font-size: 1.25rem;
    flex: 0 0 auto;
    border-bottom: 1px solid bs.$medium-gray;
  }
  :global(.modal-title) {
    font-size: 1.25rem;
  }

  :global(.modal-body) {
    padding: 0 1rem 0 calc(1rem - 5px);
    flex: 1 1 auto;

    overflow: hidden;
  }

  :global(.modal-footer) {
    padding: 0.5rem 1rem 1rem !important;
    flex: 0 0 auto;
    background-color: bs.$smoke-bg;
    align-items: flex-start;
    gap: 3rem;
  }

  .main-panels {
    display: grid;
    grid-template-columns: 300px 1fr;
    width: 100%;
    height: 100%;
  }

  .tree {
    border-right: 1px solid bs.$medium-gray;
    padding-right: 0.25rem;
    padding-left: 5px;
    overflow-y: auto;
    height: 100%;
  }
}
.capability-tree {
  font-size: 0.8rem;
}

.node {
  position: relative;
}

.node-body {
  position: relative;
  display: grid;
  grid-template-columns: min-content min-content 1fr;
  // grid-template-areas:
  //   'toggle state name'
  //   'children children children';
  // // gap: 0.1rem;

  > * {
    position: relative;
    z-index: 1;
  }
}

.active-item::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: bs.$info-color;
  opacity: 0.15;
  border-radius: bs.$border-radius-small;
}

.node-children {
  // grid-area: children;
  padding-left: 12px;
}

.node-state {
  // grid-area: state;
}

.node-name {
  // grid-area: name;
  padding-left: 0.1rem;
}

.btn-node-name,
.btn-node-toggle {
  border: none;
  background-color: transparent;
  padding: 0 0.25rem;
  padding: 0;
  margin: 0;

  &:active,
  &:focus {
    outline: none;
  }
}

.btn-node-toggle {
  color: bs.$very-dark-gray;
}

.btn-node-name {
  padding: 0;

  &:hover {
    text-decoration: underline;
    color: bs.$primary-color;
  }
}

.child-span {
  position: absolute;
  height: calc(100% - 30px);
  width: 1px;
  top: 20px;
  // left: 6.5px;
  left: calc(0.625em - 0.5px);
  background-color: #ccc;
}

.picker {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.picker-header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  background-color: bs.$smoke-bg;
}

.picker-breadcrumbs {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.picker-actions {
  flex: 0 0 auto;

  :global(.btn) {
    font-size: 0.8rem;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0.25rem 0.5rem;
  }
}

.picker-body {
  flex: 1 1 auto;
}

.picker-children {
  margin: 0.25rem 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
}

.picker-child {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: start;
  justify-items: start;
  gap: 0.5rem;

  button {
    text-align: left;
  }
}

.picker-child-button {
  padding: 0;
  // margin: 0 0.5rem 0 0;
  border: none;
  background-color: transparent;
  // color: unset;
  outline: none;

  &:active,
  &:focus {
    outline: none;
  }
}

.tags {
}

.tag {
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 0.8rem;
  padding: 2px 6px;
  border-radius: bs.$border-radius-small;

  button {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    color: unset;
    padding: 2px 6px !important;
    border-radius: bs.$border-radius-small;
    margin: -2px -6px !important;
    outline: none;
  }
}

.tag-name {
  display: inline-block;
  margin-right: 0.5em;
}

.selector-controls {
  :global(.btn) {
    min-width: 80px;
  }
}
