@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.welcome-form {
  padding: 1rem;
  // overflow-y: auto;

  :global(.card) {
    border-radius: 1rem;
  }

  :global(.btn) {
    width: 100%;
  }

  h5 {
    margin-bottom: 0;
  }
  :global(.subtitle) {
    font-size: 0.8rem;
  }

  :global(.card) {
    margin-bottom: 0;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }
}

.logo {
  margin: 1rem auto 0.5rem;
  text-align: center;
}

.gem {
  font-size: 2rem;
  width: 2.5em;
  height: 2.5em;
  background-color: bs.$blue;
  border-radius: 50%;
  color: #fff;
  display: grid;
  justify-content: center;
  align-content: center;
  margin: -0.5rem auto 0.5rem;
}

.help-text {
  font-size: 0.65rem;
  text-align: center;
  margin: 1rem auto 0;

  p {
    width: 21em;
    margin: 0 auto;
  }
}
