@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.capability-list {
  font-size: 0.8rem;
}

.chart-placeholder {
  height: 250px;
  width: 100%;
  background-color: bs.$chart-stroke-color;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: bs.$no-response-marker-color;
}

.capability-results {
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }

  .capability-name {
    font-size: 1.2rem;
    color: bs.$blue;
    margin-bottom: 0.25rem;
  }
}

.capability-elements {
  display: flex;
  gap: 1rem;
}

.capability-overview {
  flex: 0 0 auto;
  width: 250px;
}

.capability-questions {
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1rem;
}

:global(.capability-matrix) {
  :global(.chart-grid) {
    stroke-dasharray: 2, 2;
  }
  :global(.chart-axis) {
    stroke: rgba(200, 200, 200, 1);
  }
}

.capability-path {
  display: flex;
  flex-flow: row-reverse wrap-reverse;
  width: 100%;
  align-items: flex-start;
  justify-content: left;
  // flex-direction: row;
  gap: 0.5rem;
  color: bs.$dark-gray;
}

.path-list-item {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 0.5rem;
}

.path-list-item-name {
  font-size: 0.8rem;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.path-list-item-chevron {
  font-size: 0.8rem;
}
