@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.status-badge {
  font-size: 0.6rem;
  padding: 0.2rem 0.5rem;
  border-radius: bs.$border-radius-small;
  border: 1px solid transparent;
  text-transform: uppercase;
  margin-bottom: 18px;

  &.draft {
    border-color: bs.$faded-orange;
    color: bs.$faded-orange;
  }

  &.published {
    border-color: bs.$faded-blue;
    color: bs.$faded-blue;
  }

  &.archived {
    border-color: bs.$medium-gray;
    color: bs.$medium-gray;
  }

  &.live {
    border-color: bs.$faded-green;
    color: bs.$faded-green;
  }

  &.closed {
    border-color: bs.$faded-purple;
    color: bs.$faded-purple;
  }
}
