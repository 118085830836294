@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.capability-name {
  font-size: 1.2rem;
  // font-weight: bold;
  margin-bottom: 0;
}

.page-summary {
  padding-left: 7px;
  position: relative;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    height: calc(100% - 5px);
    position: absolute;
    left: 0;
    top: 3px;
    border-left: 2px solid bs.$green;
  }

  label {
    margin-bottom: 0;
  }

  section {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tool-actions {
  position: absolute;
  font-size: 0.6rem;
  right: 0.5rem;
  top: 0.5rem;
}
