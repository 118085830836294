.wp-breadcrumbs-container {
  display: flex;
  max-width: 100%;
}

.wp-breadcrumbs {
  font-size: 0.8rem;
  display: flex;
  flex-flow: row-reverse wrap-reverse;
  max-width: 100%;
  align-items: flex-start;
  justify-content: left;
  gap: 0.5rem;

  a {
    color: $white-color;
  }
}

.wp-breadcrumb {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 0.5rem;
}

.wp-breadcrumb-label {
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.wp-breadcrumb-label {
  .btn-breadcrumb {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    font-size: 0.8rem;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;

    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
}
// .wp-breadcrumb-separator {
// }
