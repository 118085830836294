@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.capability-element {
  .capability-element-name {
    padding-left: 0;
    color: bs.$blue;
  }

  &:nth-child(even) > * {
    // background-color: bs.$smoke-bg;
  }

  &:last-child .capability-element-text,
  &:last-child .capability-element-settings {
    border-bottom: none;
  }
}

.capability-element-header {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  margin: 0 -0.25rem;

  & > * {
    padding: 0.25rem 0.5rem;
  }
}

.capability-element-preview {
  padding: 0.25rem 0.5rem;
  background-color: bs.$white-color;
  width: 400px;
  font-size: 0.8rem;

  .capability-element-header {
    margin-left: -0.5rem;
    width: calc(100% + 0.5rem);
  }

  .capability-element-name {
    padding-left: 0;
    color: bs.$blue;
  }

  .capability-element-text {
    border-bottom: none;
    padding-bottom: 0;
    margin: 0;
  }
}

.capability-element-text,
.capability-element-settings {
  margin: 0 0.25rem;
}

.capability-element-text {
  padding: 0 0 0.25rem;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: bs.$dark-gray;
  // padding-top: 0;
  border-bottom: 1px solid #dee2e6;

  &:not(:last-child) {
    border-bottom: none;
  }
}

.capability-element-settings {
  padding: 0.25rem 0.5rem;
  color: bs.$dark-gray;
  background-color: bs.$smoke-bg;
  border-bottom: 1px solid #dee2e6;
  font-size: 1rem;

  thead {
    font-size: 0.8rem;
  }
}

.capability-elements-actions {
  text-align: right;
  position: absolute;
  top: 3px;
  right: 18px;
}

.capability-element-actions {
  padding: 0;
  :global(.btn-inline-action):not(:first-child) {
    margin-left: 0;
  }

  button {
    color: bs.$dark-gray;
    border: none;
    background-color: transparent;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      outline: none;
      box-shadow: none;
    }
  }

  button {
    position: relative;
    z-index: 2;
    margin: 0;
    width: 100%;
    height: 100%;
    // background-color: bs.$smoke-bg;
    border-radius: 0;

    svg {
      transition: transform 200ms;
    }
  }
}

.choices-controls {
  display: grid;
  grid-template-columns: 1fr min-content;
  width: 100%;
}
