@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.editor-grid {
  margin-top: -30px;
  margin-bottom: -30px;
}

.capability-question {
  background-color: bs.$white-color;
  border-radius: bs.$border-radius-small;
  // padding: 5px 15px;
  margin-bottom: 0.5rem;
  border: 1px solid bs.$medium-gray;
  position: relative;

  &:global(.drag-preview) {
    width: 400px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    padding-bottom: 0.5rem;

    :global(.drag-preview-hide) {
      display: none;
    }

    label {
      color: bs.$very-dark-gray;
      font-size: 0.8rem;
    }
  }

  :global(.drop-indicator-top) {
    top: calc(-0.25rem - 3.5px);
  }

  :global(.drop-indicator-bottom) {
    bottom: calc(-0.25rem - 3.5px);
  }
}

.page {
  border-radius: bs.$border-radius-small;
  margin-bottom: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 0.25rem 0.5rem;
  position: relative;

  label {
    color: bs.$very-dark-gray;
    font-size: 0.8rem;
  }

  section {
    margin-bottom: 1rem;
  }
}

.page-capabilities {
  background-color: bs.$panel-green;

  &:global(.dragged-over) {
    background-color: darken(bs.$panel-green, 10%);
  }
}

.question-header,
.page-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  label:last-child {
    margin-bottom: 0;
  }

  .page-guid {
    flex-grow: 1;
    text-align: right;
  }
}

.question-header {
  padding: 5px 15px 0;
  // padding-top: 5px;
}

.page-guid {
  font-size: 0.8rem;
  color: bs.$dark-gray;
  text-align: right;
}

.drag-handle {
  flex-grow: 0;
}
.page-title {
  flex-grow: 1;
  font-size: 1.5rem;
}

.page-description {
  margin-bottom: 1rem;
}

.capability-subset .capability-subset {
  margin-left: 12px;
}

.capability-subset {
  margin-bottom: 0.5rem;
  border: 0px solid bs.$light-gray;
  // padding: 3px 10px;
  border-radius: bs.$border-radius-small;
  font-size: 0.8rem;
  position: relative;
  // margin-top: 19px;

  &:last-child {
    margin-bottom: 0px;
  }

  label {
    margin: 0.25rem 0 0 12px;
    font-size: 0.6rem;
  }

  .capability-subset-bar {
    position: absolute;
    top: 18px;
    left: 5px;
    width: 1px;
    height: calc(100% - 22px);
    background-color: bs.$dark-gray;
  }
}

.capabilities-list-header {
  position: relative;
}

.capabilities-list-header-actions {
  position: absolute;
  right: 0;
  top: 0;
}

.capability-subset-name {
  // line-height: 20px;
  // padding: 0 0.5rem;
  // border-radius: 10px;
  // position: absolute;
  // top: -10px;
  // left: 2px;
}

.shallow-children {
  // margin-top: 10px;
  margin-left: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 5px;
}

.deep-children ~ .shallow-children {
  margin-top: 0px;
}

// .settings-wrapper {
//   .settings-drawer {
//     padding: 1rem;
//     background-color: #f8f9fa;
//     border-top: 1px solid #dee2e6;

//     .settings-content {
//       .settings-field {
//         margin-bottom: 1rem;

//         label {
//           display: block;
//           font-size: 0.875rem;
//           font-weight: 500;
//           margin-bottom: 0.5rem;
//           color: #495057;
//         }
//       }
//     }
//   }
// }

.capability-set-badge .page-badge {
  border-radius: bs.$border-radius-extreme;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;

  &.invalid-badge {
    color: bs.$dark-gray;
    font-style: italic;
  }
}
