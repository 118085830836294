.header-icon {
  margin-right: 0.3em;
}

.icon-left,
.btn-icon-left {
  margin-right: 0.4em;
}

.icon-right,
.btn-icon-right {
  margin-left: 0.4em;
}

.btn-hero {
  .btn-icon-left {
    margin-right: 0.6em;
  }

  .btn-icon-right {
    margin-left: 0.6em;
  }
}

.label-icon {
  margin-right: 0.3em;
}
