@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.modal {
  :global(.modal-body) {
    padding-top: 0.5rem;
  }

  label {
    font-size: 0.8rem;
    color: bs.$dark-gray;
  }
}

.modal-path-list {
  display: flex;
  flex-flow: row-reverse wrap-reverse;
  width: 100%;
  align-items: flex-start;
  justify-content: left;
  // flex-direction: row;
  gap: 0.5rem;
}

.modal-path-list-item {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 0.5rem;
}

.modal-path-list-item-name {
  font-size: 0.8rem;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.modal-path-list-item-chevron {
  font-size: 0.8rem;
}
