//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$sans-serif-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;

// $none:                       0  ;
// $border-thin:                1px;
// $border-thick:               2px;

$white-color: #ffffff;
$black-color: #2c2c2c;
$white-bg: #ffffff;
// $orange-bg:                  #e95e38;

$smoke-bg: #f5f5f5;
// $light-black:                #444   ;

// $black-bg:                   rgba(30,30,30,.97);

$black-color: #2c2c2c;
// $black-hr:                   #444444;

// $hr-line:                    rgba(0,0,0, .1);
$hr-card: #dee2e6;

$light-gray: #e3e3e3;
$medium-gray: #cccccc;
$dark-gray: #9a9a9a;
$very-dark-gray: #6b6b6b;

// $opacity-gray-3:             rgba(222,222,222, .3);
// $opacity-gray-5:             rgba(222,222,222, .5);
// $opacity-gray-8:             rgba(222,222,222, .8);

// $opacity-5:                  rgba(255,255,255, .5);
// $opacity-8:                  rgba(255,255,255, .8);

// $datepicker-color-days:             rgba(255,255,255, .8) ;
// $datepicker-color-old-new-days:     rgba(255,255,255, .4) ;

// $opacity-1:                  rgba(255,255,255, .1);
// $opacity-2:                  rgba(255,255,255, .2);

// $transparent-bg:             transparent;
// $dark-background:            #555555;

$red: #ed2228;
$green: #4c9745;
$yellow: #fae314;
$orange: #ff9f46;
$blue: #28b3d1;
$purple: #9d4c8e;
$deep-purple: #3e1e21;

$inverse-red: adjust-color($red, $lightness: -20%, $saturation: 10%);
$inverse-yellow: adjust-color($yellow, $lightness: -30%, $saturation: 15%);
$inverse-green: adjust-color($green, $lightness: -15%, $saturation: 10%);

$text-muted: #6c757d;
$body-action-font-color: #556066;
$body-subdued-font-color: #ababab;
$background-impression-color: #ababab;
$background-impression-light-color: #f5f5f5;

$faded-blue: #a2eafa;
$faded-green: #b9d6b6;
$faded-yellow: #f9f1aa;
$faded-red: #f8a6a9;
$faded-orange: #f4c294;
$faded-purple: #fab5ef;

$panel-red: #ffedee;
$panel-green: #e4f0e3;
$panel-yellow: #f7f5e0;
$panel-orange: #fff0e2;
$panel-blue: #d3ebf1;
$panel-purple: #faeff8;

$poor-background: $faded-red;
$meh-background: $faded-yellow;
$good-background: $faded-green;

$chart-stroke-color: #526067;
$no-response-marker-color: #8e8e8e;
$no-response-hash-color-light: #dfdfdf;
$no-response-hash-color-dark: #bfbfbf;
$chart-link-color: #304e56;

$default-color: $purple;
$default-states-color: lighten($default-color, 6%);
// $default-color-opacity:      rgba(182, 182, 182, .6);

$primary-color: $purple;
// $primary-states-color:       lighten($primary-color, 6%);
$primary-color-opacity: rgba(#da11b5, 0.3);
$primary-color-alert: rgba(#da11b5, 0.8);

$action-color: $blue;

$success-color: $green;
// $success-color:              #286e24;
// $success-states-color:       lighten($success-color, 6%);
// $success-color-opacity:      rgba(24, 206, 15, .3);
// $success-color-alert:        rgba(24, 206, 15, .8);

$secondary-color: #a34949;
$secondary-states-color: lighten($secondary-color, 6%);
$secondary-color-opacity: change-color(
  $secondary-color,
  $saturation: 85%,
  $lightness: 45%,
  $alpha: 0.3
);
$secondary-color-alert: change-color($secondary-color-opacity, $alpha: 0.8);

$info-color: $blue;
// $info-states-color:          lighten($info-color, 6%);
$info-color-opacity: change-color(
  $info-color,
  $saturation: 94%,
  $lightness: 100%,
  $alpha: 0.3
);
$info-color-alert: change-color($info-color-opacity, $alpha: 0.8);

$warning-color: #f8a826;
// $warning-states-color:       lighten($warning-color, 6%);
$warning-color-opacity: rgba(248, 168, 38, 0.3);
$warning-color-alert: rgba(248, 168, 38, 0.8);

$error-color: $red;
$danger-color: $error-color;
// $danger-states-color:        lighten($danger-color, 6%);
// $danger-color-opacity:       rgba(255, 54, 54, .3);
// $danger-color-alert:         rgba(255, 54, 54, .8);

// $orange-color:               #f96332;

// /*     brand Colors              */
// $brand-primary:              $primary-color;
// $brand-info:                 $info-color;
// $brand-success:              $success-color;
// $brand-warning:              $warning-color;
// $brand-danger:               $danger-color;
// $brand-inverse:              $black-color;
$logo-blue: #28b3d1;

// $link-disabled-color:        #666666;

// /*      light colors         */
// $light-blue:                 rgba($primary-color, .2);
// $light-azure:                rgba($info-color, .2);
// $light-green:                rgba($success-color, .2);
// $light-orange:               rgba($warning-color, .2);
// $light-red:                  rgba($danger-color, .2);

$skeleton-bone-color: #d2cfcb;

$add-item-dark-bg: #cbd0d2;

// //== Components
// //

// $padding-input-vertical:        11px;
// $padding-input-horizontal:      19px;

// $padding-btn-vertical:         11px;
// $padding-btn-horizontal:       22px;

// $padding-base-vertical:        .5rem;
// $padding-base-horizontal:      .7rem;

// $padding-round-horizontal:     23px;

// $padding-simple-vertical:      10px;
// $padding-simple-horizontal:    17px;

// $padding-large-vertical:       15px;
// $padding-large-horizontal:     48px;

// $padding-small-vertical:        5px;
// $padding-small-horizontal:     15px;

// // $padding-xs-vertical:           1px;
// // $padding-xs-horizontal:         5px;

// $padding-label-vertical:        2px;
// $padding-label-horizontal:     12px;

// $margin-large-vertical:        30px;
// $margin-base-vertical:         15px;

// $margin-base-horizontal:       15px;

// $margin-bottom:                 10px       ;
// $border:                        1px solid  ;
// $border-radius-extra-small:     0.125rem   ;
$border-radius-small: 0.1875rem;
$border-radius-large: 0.25rem;
$border-radius-extreme: 0.875rem;

// $border-radius-large-top:      $border-radius-large $border-radius-large 0 0;
// $border-radius-large-bottom:   0 0 $border-radius-large $border-radius-large;

$btn-round-radius: 3px;

// $height-base:                  55px        ;

// $btn-icon-size:                 3.5rem      ;
// $btn-icon-size-regular:         2.375rem     ;
// $btn-icon-font-size-regular:    0.9375rem    ;
// $btn-icon-font-size-small:      0.6875rem     ;
// $btn-icon-size-small:           1.875rem    ;
// $btn-icon-font-size-lg:         1.325rem    ;
// $btn-icon-size-lg:              3.6rem        ;

// $font-size-h1:                 3.5em       ; // ~ 49px
// $font-size-h2:                 2.5em       ; // ~ 35px
// $font-size-h3:                 2em         ; // ~ 28px
// $font-size-h4:                 1.714em     ; // ~ 24px
// $font-size-h5:                 1.57em      ; // ~ 22px
// $font-size-h6:                 1em         ; // ~ 14px

// $font-paragraph:               1em         ;
// $font-size-navbar:             1em         ;
// $font-size-mini:               0.7142em    ;
$font-size-small: 0.8571em;
// $font-size-base:               14px        ;
// $font-size-large:              1em         ;
// $font-size-large-navbar:       20px        ;
// $font-size-blockquote:         1.1em       ; // ~ 15px

// $font-weight-light:             300        ;
// $font-weight-normal:            400        ;
// $font-weight-semi:              600        ;
// $font-weight-bold:              700        ;

// $line-height-general:        1.5           ;
// $line-height-nav-link:       1.625rem      ;
// $btn-icon-line-height:       2.4em         ;
// $line-height:                1.35em        ;
// $line-height-lg:             54px          ;

// $border-radius-top:        10px 10px 0 0    ;
// $border-radius-bottom:     0 0 10px 10px    ;

$dropdown-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.125);
// $box-shadow-raised:        0px 10px 25px 0px rgba(0, 0, 0, 0.3);
// $box-shadow:               0 1px 15px 1px rgba(39, 39, 39, 0.1);
// $sidebar-active-box-shadow:   0 5px 20px 1px rgba(39, 39, 39, 0.25);
// $sidebar-box-shadow:       0px 2px 22px 0 rgba(0, 0, 0,.20), 0px 2px 30px 0 rgba(0, 0, 0,.35);

// $general-transition-time:  300ms            ;

// $slow-transition-time:           370ms      ;
// $dropdown-coordinates:      29px -50px      ;

// $fast-transition-time:           150ms      ;
// $select-coordinates:         50% -40px      ;

// $transition-linear:         linear          ;
// $transition-bezier:         cubic-bezier(0.34, 1.61, 0.7, 1);
// $transition-ease:           ease 0s;

// //$navbar-padding-a:               9px 13px;
// $navbar-margin-a:                15px 0px;

// $padding-social-a:               10px 5px;

// $navbar-margin-a-btn:            15px 0px;
// $navbar-margin-a-btn-round:      16px 0px;

// $navbar-padding-a-icons:         6px 15px;
// $navbar-margin-a-icons:          6px  3px;

// $navbar-padding-base:            0.625rem;
// //$navbar-margin-brand:             5px  0px;

// $navbar-margin-brand-icons:      12px auto;
// $navbar-margin-btn:              15px  3px;

// $height-icon-sm:				 32px;
// $width-icon-sm:					 32px;
// $padding-icon-sm:			     4px;
// $border-radius-icon-sm:			 7px;

// $height-icon-message:			 40px;
// $width-icon-message:			 40px;

// $height-icon-message-sm: 		 20px;
// $width-icon-message-sm:			 20px;

// $white-navbar:                  rgba(#FFFFFF, .96);
// $primary-navbar:                rgba(#34ACDC, .98);
// $info-navbar:                   rgba(#5BCAFF, .98);
// $success-navbar:                rgba(#4CD964, .98);
// $warning-navbar:                rgba(#FF9500, .98);
// $danger-navbar:                 rgba(#FF4C40, .98);

// $topbar-x:             topbar-x;
// $topbar-back:          topbar-back;
// $bottombar-x:          bottombar-x;
// $bottombar-back:       bottombar-back;

// //Nucleo Icons

// $nc-font-path:        '../fonts';
// $nc-font-size-base:   14px;
// $nc-css-prefix:       now-ui;
// $nc-background-color:     #eee;
// $nc-li-width:         (30em / 14);
// $nc-padding-width:    (1em/3);

// // Social icons color
// $social-twitter:                   #55acee;
// $social-twitter-state-color:       darken(#55acee, 5%);

// $social-facebook: 			       #3b5998;
// $social-facebook-state-color:      darken(#3b5998, 5%);

// $social-google: 			       #dd4b39;
// $social-google-state-color:        darken(#dd4b39, 5%);

// $social-linkedin: 			         #0077B5;
// $social-linkedin-state-color:        darken(#0077B5, 5%);

// // Sidebar variables
// $sidebar-width:              calc(100% - 260px);
// $sidebar-mini-width:         calc(100% - 80px);

// // Social icons color
// $social-twitter:                   #55acee;
// $social-twitter-state-color:       lighten(#55acee, 6%);

// $social-facebook: 			       #3b5998;
// $social-facebook-state-color:      lighten(#3b5998, 6%);

// $social-google: 			       #dd4b39;
// $social-google-state-color:        lighten(#dd4b39, 6%);

// $social-linkedin: 			       #0077B5;
// $social-linkedin-state-color:      lighten(#0077B5, 6%);

// $social-pinterest: 			       #cc2127;
// $social-pinterest-state-color:     lighten(#cc2127, 6%);

// $social-dribbble: 		           #ea4c89;
// $social-dribbble-state-color:      lighten(#ea4c89, 6%);

// $social-github: 		      	   #333333;
// $social-github-state-color:        lighten(#333333, 6%);

// $social-youtube: 		    	   #e52d27;
// $social-youtube-state-color:       lighten(#e52d27, 6%);

// $social-instagram: 		           #125688;
// $social-instagram-state-color:     lighten(#125688, 6%);

// $social-reddit: 		  	       #ff4500;
// $social-reddit-state-color:        lighten(#ff4500, 6%);

// $social-tumblr: 			       #35465c;
// $social-tumblr-state-color:        lighten(#35465c, 6%);

// $social-behance: 			       #1769ff;
// $social-behance-state-color:       lighten(#1769ff, 6%);

// $component-active-color:      $white;
$component-active-bg: $green;

// Form validation
$form-feedback-warning-color: $warning-color;

$form-feedback-icon-warning-color: $form-feedback-warning-color !default;
// $form-feedback-icon-warning:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-warning-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-warning-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"), "#", "%23") !default;
$form-feedback-icon-warning: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-warning-color}' viewBox='0 0 16 16'%3e%3cpath stroke='#{$form-feedback-icon-warning-color}' d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z'/%3e%3cpath stroke='#{$form-feedback-icon-warning-color}' d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z'/%3e%3c/svg%3e"),
  '#',
  '%23'
) !default;
// $form-feedback-icon-warning:        url(str-replace("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='red' viewBox='-2 -2 7 7'%3e%3cpath stroke='red' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E", "#", "%23")) !default;

$minimal-layout-header-height: 40px;
