@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.capability-set {
  font-size: 1.25rem;
  position: relative;
  padding-bottom: 0.25rem;
  border-radius: bs.$border-radius-small;

  &:hover {
    background-color: bs.$light-gray;
  }

  &.drop-target {
    background-color: bs.$faded-blue;
  }
}

.capability-set-name {
  padding: 4px 6px;
}

.capability {
  border-radius: bs.$border-radius-small;
  font-size: 1rem;
  position: relative;
  padding-bottom: 0.25rem;

  &:hover {
    background-color: bs.$light-gray;
  }

  &.drop-target {
    background-color: bs.$faded-blue;
  }

  .capability-body {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 0.5rem;
    margin: 0 0.25rem;
  }

  .capability-body-content {
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
  }

  .child-toggle {
    align-self: start;
    color: bs.$body-subdued-font-color;
  }

  .capability-actions {
    padding: 0.5rem 0;
  }

  .tree-item:global(.drag-preview) {
    background-color: white;
    z-index: 2;
    max-width: 350px;
    color: black;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem;

    .capability-body {
      grid-template-columns: 0 1fr;
      gap: 0;
    }

    .child-toggle {
      visibility: hidden;
    }
  }
}

.children {
  position: relative;
}

.partial-gutter,
.gutter {
  position: absolute;
  top: 0;
  left: 0.75rem;
  width: 1px;
  height: calc(100%);
  border-left: 1px solid #ccc;
  z-index: 1;
  pointer-events: none;
}

.partial-gutter {
  height: calc(100% - 1.5rem);
  top: 1.5rem;
}

.capability-name {
  font-size: 1rem;
}

.capability-description {
  font-size: 0.6rem;
  color: bs.$text-muted;
}

.capability-name,
.capability-description {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.add-capability {
  position: relative;
}

.tree-item {
  color: bs.$black-color;
  border: 0;
  width: 100%;
  position: relative;
  background: transparent;
  margin: 0;
  padding: 0;
  border-radius: bs.$border-radius-small;
  cursor: pointer;

  padding: 0.25rem 5rem;
  // width: 100%;
  // margin: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }
}

.capability-menu {
  font-size: 1rem;
  position: absolute;
  right: 0.5rem;
  top: 0.15rem;

  button {
    margin: 0;
  }
}
