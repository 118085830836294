.assessment-review-header {
  margin-top: -30px;

  .details {
    .actions {
      margin-top: 1em;

      @media #{$medium-up} {
        text-align: right;
      }
    }

    .timestamp {
      position: relative;
      top: -0.5em;
      margin-bottom: 0.5em;
    }

    &.flex {
      display: flex;
      gap: 2rem;
      flex-direction: column;

      .details-sidebar {
        flex: 0 0 250px;
      }
      .details-main {
        flex: 1 1 auto;
      }

      @media #{$medium-up} {
        flex-direction: row;
      }
    }
  }
}

.survey-assessment .assessment-review-header {
  @media #{$medium-up} {
    .participation-stats {
      margin-bottom: -300px;
    }
  }
}

.assessment-review-header .details-sidebar {
  @media #{$medium-up} {
    .participation-stats {
      // margin-bottom: 0;
    }
  }
}
