@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.hidden {
  display: none !important;
}
.header {
  display: grid;
  grid-template-columns: min-content min-content max-content;
  align-items: center;
}
.saving {
  .name-grid-item {
    width: unset;
  }
  .set-selector-grid-item {
    width: 0;
    overflow: hidden;
  }
}
.name-grid-item {
  width: 0;
  overflow: hidden;
}

.choice-list {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.choice-list-null {
  margin-bottom: 1rem;

  .add-choice {
    padding: 0;
  }
}

.choice {
  position: relative;
  font-size: 0.8rem;
  width: 150px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: bs.$border-radius-small;

  .drag-handle {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px;
    cursor: grab;
    color: #ababab;
    z-index: 0;
  }

  .choice-wrapper {
    padding: 1.75rem 1rem 1rem;

    &:hover {
      // margin: -1px 0;
      background: bs.$smoke-bg;

      .drag-handle {
        display: block;
      }

      .choice-actions {
        display: block;
      }
    }

    &:global(.drag-preview) {
      border-radius: 3px;
      background-color: #fff;
      position: relative;
      z-index: 100;

      .choice-actions {
        display: none;
      }
    }
  }

  .choice-actions {
    position: absolute;
    top: 3px;
    right: 3px;
    display: none;
  }

  &:not(:first-child):not(:global(.drag-preview))::before {
    content: '';
    display: block;
    width: calc(100% - 60px);
    height: 2px;
    background-color: bs.$dark-gray;
    margin-bottom: 1rem;
    position: absolute;
    top: calc(1.75rem + 15px);
    right: calc(50% + 30px);
    z-index: 2;
  }
}

.choice-value-marker {
  height: 26px;
  width: 26px;
  border: 3px solid bs.$blue;
  border-radius: 3px;
  margin: 0 auto 1rem;

  &.radio {
    border-radius: 50%;
  }
}

.choice-score {
  font-size: 0.6rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
  line-height: 20px;

  :global(.editor-input) {
    &:not(:has(button)) {
      padding: 3px 0;
    }

    :global(.focus-container) {
      margin: 0 auto;
      // position: relative;
      // top: -3px;

      button {
        width: 26px;
        height: 26px;
        border: 3px solid bs.$blue;
        border-radius: 50%;
        border-radius: 50%;
      }
    }
  }

  input {
    border: 3px solid bs.$red;
    font-size: 0.6rem;
    border-radius: 50%;
    max-width: 26px;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 20px;
    padding: 0 0.2em;
    margin: 0 auto;

    &:focus {
      border-width: 3px;
    }
  }
  // place-items: center;
  // display: table-cell;
  // vertical-align: middle;
}

.choice-text {
  line-height: 1.2;

  button {
    width: 100%;
  }

  :global(.editor-input button .editor-input-button-text) {
    text-align: center;
  }

  :global(.editor-input textarea) {
    resize: none;
    width: calc(100% + 2px);
    max-width: calc(100% + 2px);
    font-size: 0.8rem;
    height: unset;
    padding: 5px 5px;
    margin: -3px -1px;
    text-align: center;
  }

  :global(.focus-container) {
    width: 100%;

    :global(.editor-input-button-text) {
      text-align: center;
    }
  }
}

.add-choice {
  width: 132px;
  padding-bottom: 1rem;

  button {
    height: 110px;
    width: 130px;
    margin: 0;

    &:focus:not(:hover) :global(.icon) {
      color: bs.$white-color;
    }

    :global(.icon) {
      color: bs.$primary-color;
      transition: color 200ms;
    }

    // &:focus {
    //   border: 2px solid red;
    // }
  }
}

.add-choice-text {
  display: block;
  margin-top: 0.4rem;
}

.choose-answer-set-or {
  justify-content: center;
  align-content: center;
  padding: 1rem 4rem;
}
.choose-answer-set-inline {
  padding: 1rem;
  align-content: center;
  justify-content: center;

  :global(.action-selector) {
    width: 100%;

    > button {
      width: 100%;
    }
  }
}

.drop-indicator {
  &.drop-indicator-vertical {
    pointer-events: none;
    z-index: 2;
    top: 0;
    width: 4px;
    height: calc(100% - 8px);
    position: absolute;
    margin-top: 8px;
    border-radius: 0 0 2px 2px;
  }

  &.drop-indicator-left {
    left: -3px;
    right: unset;
  }
  &.drop-indicator-right {
    left: unset;
    right: -3px;
  }
}

.drop-indicator::before {
  content: '';
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  border: 2px solid bs.$blue;
  position: absolute;
  top: -8px;
  left: -3.5px;
}
