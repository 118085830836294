@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.page-review {
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 1rem;
  }

  :global(.no-response-fill) {
    fill: rgba(bs.$no-response-marker-color, 0.5);
    stroke: bs.$no-response-marker-color;
  }

  :global(.matrix-plot-point) {
    fill: bs.$blue;
  }

  :global(.arrow-marker) {
    fill: context-fill;
    stroke: context-stroke;
  }
}

.page-review-title {
  font-size: 1.2rem;
  color: bs.$blue;
  margin-bottom: 0;
  margin-top: 0;
}

.page-review-body {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, min-content));
  gap: 1rem 3rem;
}

.capability {
  display: flex;
  flex-direction: column;
}

.capability-title {
  flex-grow: 1;
  font-size: 0.8rem;
  color: bs.$dark-gray;
  margin: 0;
  display: flex;
  align-items: flex-end;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: bs.$primary-color;
    }
  }
}

.capability-charts {
  flex-grow: 0;
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 1rem;
  margin-left: -5px;
}

.legend {
  display: flex;
  font-size: 0.8rem;
  gap: 1rem;
  margin: 1rem 0;
}
.legend-item :global(.palette-fill) path {
  fill: inherit;
}
